import {
  WppButton,
  WppTypography,
  WppActionButton,
  WppIconPlus,
  WppCard,
  WppIconTrash,
  WppAccordion,
  WppIconCopy,
  WppTooltip,
  WppRadio,
} from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { useEffect, useCallback } from 'react'
import { FormProvider, useFieldArray, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCreatePhaseApi } from 'api/phase/mutations/useCreatePhaseApi'
import { useEditPhaseApi } from 'api/phase/mutations/useEditPhaseApi'
import { usePhaseApi } from 'api/phase/queries/useFetchPhaseApi'
import { useProcessApi } from 'api/process/queries/useFetchProcessApi'
import { Flex } from 'components/common/flex/Flex'
import { FormAlignment } from 'components/form/formAlignment/FormAlignment'
import { FormCheckbox } from 'components/form/formCheckbox/FormCheckbox'
import { FormDimensions } from 'components/form/formDimensions/FormDimensions'
import { FormFileUpload } from 'components/form/formFileUpload/FormFileUpload'
import { FormPalette } from 'components/form/formPalette/FormPalette'
import { FormRadioGroup } from 'components/form/formRadioGroup/FormRadioGroup'
import { FormRichText } from 'components/form/formRichText/FormRichText'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import { useToast } from 'hooks/useToast'
import styles from 'pages/phaseBuilder/editPhaseModal/EditPhaseModal.module.scss'
import { phaseSchema } from 'pages/phaseBuilder/editPhaseModal/schema'
import { getInitialValues, colorsConfig, useMapFormValuesToPayload } from 'pages/phaseBuilder/utils'
import { Templates, PhaseFormConfig, PhaseLegendVariant } from 'types/phase/phase'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  onChange: Function
  resetEditState: () => void
  phaseId?: string
  processId?: string
}

const templateOptions = Object.values(Templates).map(value => ({ value, label: value }))
const headerSizeOptions = [
  { value: 154, label: 'Sm' },
  { value: 184, label: 'Md' },
  { value: 214, label: 'Lg' },
  { value: 244, label: 'Xl' },
  { value: 274, label: 'XXl' },
]

export const EditPhaseModal = ({
  isOpen,
  onClose,
  onCloseComplete,
  onChange,
  resetEditState,
  phaseId,
  processId,
}: Props) => {
  const { enqueueToast } = useToast()
  const { t } = useTranslation()
  const {
    osContext: { tenant },
  } = useOs()
  const queryClient = useQueryClient()

  const { data } = usePhaseApi({
    params: {
      phaseId: phaseId || '',
    },
  })
  const isEditMode = Boolean(data)

  const { data: processData, isLoading: isLoadingProcess } = useProcessApi({
    params: {
      tenantId: tenant.id,
      processId,
    },
  })
  const phase1Id = processData?.phases?.find(({ order }) => order === 1)?.id

  const { data: phase1Data, isLoading: isLoadingPhase1 } = usePhaseApi({
    params: {
      phaseId: phase1Id || '',
    },
  })

  const { mutateAsync: handleCreatePhase } = useCreatePhaseApi()
  const { mutateAsync: handleEditPhase } = useEditPhaseApi()
  const mapFormValuesToPayload = useMapFormValuesToPayload()

  const form = useForm({
    defaultValues: getInitialValues(data),
    validationSchema: phaseSchema,
  })

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
    watch,
    control,
    register,
  } = form

  const onSubmit = handleSubmit(async values => {
    try {
      const phase = await mapFormValuesToPayload(values) // TODO fix mapped values not including images files after "Copy visual setup"

      isEditMode
        ? await handleEditPhase({
            phase,
            innerPhaseId: data?.id || '',
          })
        : await handleCreatePhase({
            phase,
            phaseId: phaseId || '',
          })

      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.PHASE] })

      enqueueToast({
        message: t('phase.edit_modal.configured_successfully'),
        type: 'success',
      })
      onClose()
    } catch (e) {
      enqueueToast({
        message: t('phase.edit_modal.not_configured'),
        type: 'error',
      })
    }
  })

  const handleCloseComplete = useCallback(() => {
    onCloseComplete()
    resetEditState()
  }, [onCloseComplete, resetEditState])

  const formData = watch()

  const {
    fields: columns,
    append: appendColumn,
    remove: removeColumn,
  } = useFieldArray({
    control,
    name: 'card.columns',
    keyName: 'formId',
  })

  const {
    fields: legendSections,
    append: appendLegendSection,
    remove: removeLegendSection,
  } = useFieldArray({
    control,
    name: 'legend.sections',
    keyName: 'formId',
  })

  useEffect(() => {
    onChange(formData)
  }, [formData, onChange])

  const handleAddColumn = useCallback(() => {
    appendColumn({
      content: '',
      icon: [],
    })
  }, [appendColumn])

  const handleRemoveColumn = useCallback(
    (index: number) => () => {
      removeColumn(index)
    },
    [removeColumn],
  )

  const handleAddLegendSection = useCallback(() => {
    appendLegendSection({
      title: '',
      image: [],
      colors: [],
    })
  }, [appendLegendSection])

  const handleRemoveLegendSection = useCallback(
    (index: number) => () => {
      removeLegendSection(index)
    },
    [removeLegendSection],
  )

  const handleCopyPhaseConfig = useCallback(() => {
    phase1Data?.card?.columns?.forEach((column, index) => {
      delete column.id
      column.content = formData?.card?.columns?.[index]?.content || ''
    })
    const phase1DataValues = getInitialValues(phase1Data)

    const newValues = {
      ...phase1DataValues,
      background: formData?.background || undefined,
      header: {
        ...phase1DataValues.header,
        logoImage: formData?.header?.logoImage || undefined,
        background: formData?.header?.background || undefined,
        title: formData?.header?.title || '',
        description: formData?.header?.description || '',
        groupTitle: formData?.header?.groupTitle || '',
      },
      card: {
        ...phase1DataValues.card,
        background: formData?.card?.background || undefined,
        contentImage: formData?.card?.contentImage || undefined,
      },
      controls: {
        ...formData?.controls,
        colors: phase1DataValues?.controls?.colors || formData?.controls?.colors,
        button: {
          ...formData?.controls?.button,
          isVisible: phase1DataValues?.controls?.button?.isVisible || formData?.controls?.button?.isVisible,
          title: phase1DataValues?.controls?.button?.title || formData?.controls?.button?.title,
        },
      },
    }

    Object.entries(newValues).forEach(([key, value]) => {
      if (typeof value === 'object' && value !== null) {
        Object.entries(value).forEach(([nestedKey, nestedValue]) => {
          form.setValue(`${key}.${nestedKey}` as keyof PhaseFormConfig, nestedValue, { shouldDirty: true })
        })
      } else if (value) form.setValue(key as keyof PhaseFormConfig, value, { shouldDirty: true })
    })
  }, [phase1Data, form, formData])

  return (
    <FormProvider {...form}>
      <SideModal
        formConfig={{ onSubmit }}
        size="m"
        disableOutsideClick
        open={isOpen}
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={handleCloseComplete}
        className={styles.modal}
      >
        <WppTypography slot="header" type="2xl-heading">
          {t('phase.edit_modal.edit_phase')}
        </WppTypography>

        <Flex slot="body" direction="column" gap={28} className={styles.modalBody}>
          {phase1Id !== phaseId && (
            <WppTooltip config={{ placement: 'bottom' }} text={t('phase.edit_modal.copy_visual_setup_info')}>
              <WppButton
                variant="primary"
                onClick={handleCopyPhaseConfig}
                loading={isLoadingProcess || isLoadingPhase1}
                disabled={!phase1Data}
              >
                {t('phase.edit_modal.copy_visual_setup')}
                <WppIconCopy slot="icon-end" />
              </WppButton>
            </WppTooltip>
          )}

          <FormSelect
            labelConfig={{ text: t('phase.edit_modal.template') }}
            required
            withSearch
            placeholder={t('phase.edit_modal.choose_template')}
            name="template"
            options={templateOptions}
            defaultValue={Templates.InnerFirst}
            disabled
          />

          <WppCard>
            <Flex direction="column" gap={10}>
              <WppTypography type="s-strong" className={styles.cardTitle}>
                {t('phase.edit_modal.body')}
              </WppTypography>

              <FormFileUpload
                name="background"
                size={1}
                acceptConfig={{
                  'image/svg+xml': ['.svg'],
                  'image/png': ['.png'],
                  'image/jpeg': ['.jpg', '.jpeg'],
                  'image/gif': ['.gif'],
                }}
                maxLabelLength={45}
                maxFiles={1}
                className={styles.fileInput}
                label={t('phase.edit_modal.choose_background')}
              />

              <FormPalette
                title={t('common.colors')}
                useDefaultColors={!isEditMode}
                name="colors"
                colorsConfig={colorsConfig[formData?.template as Templates].page}
              />
            </Flex>
          </WppCard>

          <WppCard className={styles.headerCard}>
            <Flex direction="column" gap={10}>
              <WppTypography type="s-strong" className={styles.cardTitle}>
                {t('phase.edit_modal.header')}
              </WppTypography>

              <FormTextInput name="header.title" placeholder={t('phase.edit_modal.title')} />

              <FormTextInput name="header.description" placeholder={t('phase.edit_modal.description')} />

              <FormFileUpload
                name="header.logoImage"
                size={1}
                acceptConfig={{
                  'image/svg+xml': ['.svg'],
                  'image/png': ['.png'],
                  'image/jpeg': ['.jpg', '.jpeg'],
                  'image/gif': ['.gif'],
                }}
                maxLabelLength={45}
                maxFiles={1}
                className={styles.fileInput}
                label={t('phase.edit_modal.choose_header_logo')}
              />

              <FormDimensions
                name="header.logoDimensions"
                widthLabel={t('phase.edit_modal.logo_width')}
                heightLabel={t('phase.edit_modal.logo_height')}
                required
              />

              <Flex gap={15} align="start">
                <FormFileUpload
                  name="header.background"
                  size={1}
                  acceptConfig={{
                    'image/svg+xml': ['.svg'],
                    'image/png': ['.png'],
                    'image/jpeg': ['.jpg', '.jpeg'],
                    'image/gif': ['.gif'],
                  }}
                  maxLabelLength={45}
                  maxFiles={1}
                  className={styles.fileInput}
                  label={t('phase.edit_modal.choose_header_background')}
                />
                <FormAlignment name="header.positionBackground" />
              </Flex>

              <FormPalette
                title={t('common.colors')}
                useDefaultColors={!isEditMode}
                name="header.colors"
                colorsConfig={colorsConfig[formData?.template as Templates].header}
              />

              <FormSelect
                required
                withSearch
                placeholder={t('phase.edit_modal.header_size')}
                name="header.height"
                options={headerSizeOptions}
                defaultValue={184}
              />

              <FormCheckbox
                name="header.isFullWidth"
                labelConfig={{
                  text: t('phase.edit_modal.header_full_width'),
                }}
              />
            </Flex>
          </WppCard>

          <WppCard className={clsx({ [styles.inactiveCard]: !formData?.card?.isEnabled })}>
            <Flex direction="column" gap={10}>
              <WppTypography type="s-strong" className={styles.cardTitle}>
                {t('phase.edit_modal.card')}
              </WppTypography>

              <FormFileUpload
                name="card.background"
                size={1}
                acceptConfig={{
                  'image/svg+xml': ['.svg'],
                  'image/png': ['.png'],
                  'image/jpeg': ['.jpg', '.jpeg'],
                  'image/gif': ['.gif'],
                }}
                maxLabelLength={45}
                maxFiles={1}
                className={styles.fileInput}
                label={t('phase.edit_modal.choose_card_background')}
              />

              <FormFileUpload
                name="card.contentImage"
                size={1}
                acceptConfig={{
                  'image/svg+xml': ['.svg'],
                  'image/png': ['.png'],
                  'image/jpeg': ['.jpg', '.jpeg'],
                  'image/gif': ['.gif'],
                }}
                maxLabelLength={45}
                maxFiles={1}
                className={styles.fileInput}
                label={t('phase.edit_modal.choose_custom_content')}
              />

              <FormPalette
                title={t('common.colors')}
                useDefaultColors={!isEditMode}
                name="card.colors"
                colorsConfig={colorsConfig[formData?.template as Templates].card}
              />

              <FormCheckbox
                name="card.isEnabled"
                labelConfig={{
                  text: t('phase.edit_modal.card_enabled'),
                }}
              />

              <FormCheckbox
                name="card.isScrollable"
                labelConfig={{
                  text: t('phase.edit_modal.card_scrollable'),
                }}
              />
            </Flex>
          </WppCard>

          <WppCard>
            <Flex direction="column" gap={24}>
              <WppTypography type="s-strong" className={styles.cardTitle}>
                {t('phase.edit_modal.legend')}
              </WppTypography>

              <FormCheckbox
                name="legend.isEnabled"
                labelConfig={{
                  text: t('phase.edit_modal.legend_enabled'),
                }}
              />

              <FormFileUpload
                name="legend.iconImage"
                size={1}
                acceptConfig={{
                  'image/svg+xml': ['.svg'],
                  'image/png': ['.png'],
                  'image/jpeg': ['.jpg', '.jpeg'],
                  'image/gif': ['.gif'],
                }}
                maxLabelLength={45}
                maxFiles={1}
                className={styles.fileInput}
                label={t('phase.edit_modal.choose_legend_icon')}
              />

              <FormPalette
                title={t('common.colors')}
                useDefaultColors={!isEditMode}
                name="legend.colors"
                colorsConfig={colorsConfig[formData?.template as Templates].legend}
              />

              <FormRadioGroup name="legend.variant">
                <Flex gap={20}>
                  <WppRadio
                    value="tooltip"
                    name="legend.variant"
                    labelConfig={{
                      text: t('phase.edit_modal.legend_as_tooltip'),
                    }}
                    required
                  />
                  <WppRadio
                    value="sidebar"
                    name="legend.variant"
                    labelConfig={{
                      text: t('phase.edit_modal.legend_as_sidebar'),
                    }}
                    required
                  />
                </Flex>
              </FormRadioGroup>

              <FormFileUpload
                name="legend.image"
                size={1}
                acceptConfig={{
                  'image/svg+xml': ['.svg'],
                  'image/png': ['.png'],
                  'image/jpeg': ['.jpg', '.jpeg'],
                  'image/gif': ['.gif'],
                }}
                maxLabelLength={45}
                maxFiles={1}
                className={styles.fileInput}
                label={t('phase.edit_modal.choose_legend_image')}
                hidden={formData?.legend?.variant === PhaseLegendVariant.SIDEBAR}
              />

              <FormTextInput
                name="legend.title"
                placeholder={t('phase.edit_modal.legend_title')}
                hidden={formData?.legend?.variant === PhaseLegendVariant.TOOLTIP}
              />
              <FormTextInput
                name="legend.iconTooltip"
                placeholder={t('phase.edit_modal.tooltip_text_input_placeholder')}
                hidden={formData?.legend?.variant === PhaseLegendVariant.TOOLTIP}
              />

              {formData?.legend?.variant === PhaseLegendVariant.SIDEBAR && (
                <WppTypography type="m-strong">{t('phase.edit_modal.legend_sections_title')}</WppTypography>
              )}

              {legendSections.map((item, index) => (
                <WppCard
                  key={item.formId}
                  className={clsx({ [styles.displayNone]: formData?.legend?.variant === PhaseLegendVariant.TOOLTIP })}
                >
                  <WppAccordion
                    withDivider={false}
                    className={clsx(styles.legendSectionAccordion, {
                      [styles.displayNone]: formData?.legend?.variant === PhaseLegendVariant.TOOLTIP,
                    })}
                  >
                    <WppTypography type="s-strong" slot="header">
                      {t('phase.edit_modal.legend_section_number', { number: index + 1 })}
                    </WppTypography>
                    <WppActionButton
                      className={styles.accordionActionBtn}
                      variant="primary"
                      onClick={handleRemoveLegendSection(index)}
                      slot="actions"
                    >
                      <WppIconTrash slot="icon-start" />
                    </WppActionButton>

                    <Flex direction="column" gap={10} className={styles.legendSectionFieldsWrap}>
                      <FormTextInput
                        name={`legend.sections.${index}.title`}
                        placeholder={t('phase.edit_modal.legend_section_title_number', { number: index + 1 })}
                        hidden={formData?.legend?.variant === PhaseLegendVariant.TOOLTIP}
                      />
                      <FormFileUpload
                        name={`legend.sections.${index}.image`}
                        size={1}
                        acceptConfig={{
                          'image/svg+xml': ['.svg'],
                          'image/png': ['.png'],
                          'image/jpeg': ['.jpg', '.jpeg'],
                          'image/gif': ['.gif'],
                        }}
                        maxLabelLength={45}
                        maxFiles={1}
                        className={styles.fileInput}
                        label={t('phase.edit_modal.choose_legend_section_image')}
                        hidden={formData?.legend?.variant === PhaseLegendVariant.TOOLTIP}
                      />
                      <FormPalette
                        title={t('common.colors')}
                        useDefaultColors={!isEditMode}
                        name={`legend.sections.${index}.colors`}
                        colorsConfig={colorsConfig[formData?.template as Templates].legendSection}
                      />
                    </Flex>
                  </WppAccordion>
                </WppCard>
              ))}

              {formData?.legend?.variant === PhaseLegendVariant.SIDEBAR && (
                <WppActionButton onClick={handleAddLegendSection}>
                  <WppIconPlus slot="icon-start" />
                  {t('phase.edit_modal.legend_add_section')}
                </WppActionButton>
              )}
            </Flex>
          </WppCard>

          <WppCard>
            <Flex direction="column" gap={10}>
              <WppTypography type="s-strong" className={styles.cardTitle}>
                {t('phase.edit_modal.controls')}
              </WppTypography>

              <Flex direction="row" justify="between" align="center" gap={15}>
                <FormTextInput
                  name="controls.previousUrl"
                  placeholder={t('phase.edit_modal.previous_link')}
                  className={styles.inputHalfRow}
                />
                <FormTextInput
                  name="controls.nextUrl"
                  placeholder={t('phase.edit_modal.next_link')}
                  className={styles.inputHalfRow}
                />
              </Flex>

              <Flex direction="row" justify="between" align="center" gap={15}>
                <FormTextInput
                  name="controls.button.title"
                  placeholder={t('phase.edit_modal.button_title')}
                  className={styles.inputHalfRow}
                />
                <FormTextInput
                  name="controls.button.url"
                  placeholder={t('phase.edit_modal.button_link')}
                  className={styles.inputHalfRow}
                />
              </Flex>
              <FormCheckbox
                name="controls.button.isVisible"
                labelConfig={{
                  text: t('phase.edit_modal.button_visibility'),
                }}
              />

              <FormPalette
                title={t('common.colors')}
                useDefaultColors={!isEditMode}
                name="controls.colors"
                colorsConfig={colorsConfig[formData?.template as Templates].controls}
              />
            </Flex>
          </WppCard>

          <Flex direction="column" gap={20}>
            {columns.map((item, index) => (
              <WppCard
                key={item.formId}
                className={clsx(styles.column, { [styles.inactiveCard]: formData.card?.contentImage?.[0] })}
              >
                <WppAccordion withDivider={false} className={styles.columnAccordion}>
                  <WppTypography type="s-strong" slot="header">
                    {t('phase.edit_modal.column_number', { number: index + 1 })}
                  </WppTypography>
                  <WppActionButton
                    className={styles.removeColumnBtn}
                    variant="primary"
                    onClick={handleRemoveColumn(index)}
                    slot="actions"
                  >
                    <WppIconTrash slot="icon-start" />
                  </WppActionButton>

                  <Flex direction="column" gap={10}>
                    <Controller
                      render={({ field }) => (
                        <FormFileUpload
                          {...field}
                          disabled={false}
                          size={1}
                          acceptConfig={{
                            'image/svg+xml': ['.svg'],
                            'image/png': ['.png'],
                            'image/jpeg': ['.jpg', '.jpeg'],
                            'image/gif': ['.gif'],
                          }}
                          maxLabelLength={45}
                          maxFiles={1}
                          className={styles.fileInput}
                          label={t('phase.edit_modal.choose_icon')}
                        />
                      )}
                      name={`card.columns.${index}.icon`}
                      control={control}
                    />

                    <FormRichText {...register(`card.columns.${index}.content`)} />
                  </Flex>
                </WppAccordion>
              </WppCard>
            ))}

            <WppActionButton onClick={handleAddColumn} className={styles.addColumnBtn}>
              <WppIconPlus slot="icon-start" />
              {t('phase.edit_modal.add_column')}
            </WppActionButton>
          </Flex>
        </Flex>

        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" onClick={onClose}>
            {t('common.cancel')}
          </WppButton>
          <WppButton variant="primary" type="submit" disabled={(data && !isDirty) || !isValid} loading={isSubmitting}>
            {t('common.save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showEditPhaseModal, useModal: useEditPhaseModal } = createNiceModal<Props>(
  EditPhaseModal,
  'edit-phase-modal',
)
